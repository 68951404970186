import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      {" "}
      Made with ❤️ by <a href="https://aakansha.dev">Aakansha </a>
    </div>
  );
};

export default Footer;
